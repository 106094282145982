import React from 'react';
import pageStyle from '../../../assets/css/page.module.css';
import style from '../../../assets/css/main.module.css';
import Menu from '../../../components/menu';
import Footer from '../../../components/footer';
import HeroPage from '../../../components/hero.page';
import Hamburger from '../../../components/hamburger';
import SEO from '../../../components/seo';

import SimilarCard from '../../../components/similar.card';

import imgSEO from '../../../assets/img/services/site-e-commerce.jpg';

import similar1 from '../../../assets/img/services/contenu.jpg';
import similar2 from '../../../assets/img/services/seo.jpg';
import similar3 from '../../../assets/img/services/thumbnail/pub.jpg';
import similar4 from '../../../assets/img/services/thumbnail/graphicdesign.jpg';
import CtaSection from '../../../components/cta.section';
import cta from '../../../assets/img/illustrations/add_to_cart.svg';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Création de site e-commerce | Agence digitale | MDS Digital Agency"
      description="Développer un site e-commerce pour la vente en ligne ne s’improvise pas. Il en va de la pérennité de votre activité sur le web."
      keywords="site e-commerce"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2 className={pageStyle.header_uppercase}>Site e-commerce</h2>
      <p>
        Vous souhaitez <strong>créer votre boutique en ligne</strong> ou effectuer une refonte d’un
        site e-commerce existant ?
      </p>
      <p>
        Vous êtes un commerçant, un fabriquant, un grossiste, un artisan ? Vous souhaitez profiter
        des opportunités du commerce en ligne en vendant vos produits ou vos services 7j/7 et 24h/24
        ?
      </p>
      <p>
        Développer un site e-commerce pour la vente en ligne ne s’improvise pas. Il en va de même de
        la pérennité de votre activité sur le web.
      </p>
      <p>
        Voici <strong>étape par étape</strong> comment MDS vous accompagne dans{' '}
        <strong>la création de votre site e-commerce</strong>:
      </p>
      <ul>
        <li>Nous débutons par une analyse approfondie de votre stratégie e-commerce.</li>
        <li>
          Nous définissons avec vous la meilleure manière de présenter et mettre à jour votre
          catalogue en ligne.
        </li>
        <li>
          Notre équipe conçoit votre site e-commerce en tenant compte de{' '}
          <AniLink cover bg="#fba515" to="/services/graphic-design/">
            l'identité visuelle{' '}
          </AniLink>{' '}
          existante de votre activité (logo, couleurs, charte graphique,...). Si vous n’avez pas
          encore{' '}
          <AniLink cover bg="#fba515" to="/services/graphic-design/">
            d'identité visuelle{' '}
          </AniLink>
          , nous pouvons vous aider à en définir une.
        </li>
        <li>
          Vos textes peuvent être inclus dans les pages de votre site e-commerce et nous pouvons
          également rédiger du contenu pour vous (
          <AniLink cover bg="#fba515" to="/services/copywriting/">
            copy
          </AniLink>
          ) ainsi que nous charger des traductions.
        </li>
        <li>
          Nous créons une boutique en ligne agréable à utiliser pour vos clients et simple à mettre
          à jour pour vous.
        </li>
        <li>
          Nous nous chargeons de la réservation du nom de domaine et de l’hébergement de votre site
          e-commerce ainsi que de la création d’une adresse email personnalisée.
        </li>
        <li>Nous vous formons à la gestion de votre boutique en ligne.</li>
        <li>
          Vous bénéficierez d’un site e-commerce ergonomique, efficace et évolutif qui jouira d’un{' '}
          <AniLink cover bg="#fba515" to="/services/referencement/naturel/">
            référencement naturel
          </AniLink>{' '}
          optimal.
        </li>
      </ul>

      <CtaSection
        img={cta}
        title="Vous souhaitez élargir votre audience à l’échelle nationale voire internationale ?"
        subtitle="Un site E-commerce est la solution à votre problème."
        ctaText="Devis en ligne"
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Mise à jour de contenu"
            url="/services/mise-a-jour-contenu/"
            img={similar1}
          />
          <SimilarCard
            text="Référencement naturel (SEO)"
            url="/services/referencement/naturel/"
            img={similar2}
          />
          <SimilarCard
            text="Référencement payant (SEA)"
            url="/services/referencement/payant/"
            img={similar3}
          />
          <SimilarCard text="Graphic Design" url="/services/graphic-design/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
